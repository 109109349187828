<script>
  import { onMount } from "svelte";
  import { Router, Route, Link, navigate } from "svelte-routing";
  import AOS from "aos";
  import Image from "svelte-image";
  import Home from "./routes/Home.svelte";
  import Page from "./routes/Page.svelte";
  import Blog from "./routes/Blog.svelte";
  import Category from "./routes/Category.svelte";
  import Tag from "./routes/Tag.svelte";

  import Post from "./routes/Post.svelte";
  import Portfolio from "./routes/Portfolio.svelte";
  import Public from "./routes/Public.svelte";
  import Protected from "./routes/Protected.svelte";
  import NotFound from "./routes/NotFound.svelte";

  import AppLoader from "./common/AppLoader.svelte";
  import { user, appInfo, userList, categoryList, tagsList } from "./store.js";
  import Header from "./common/Header.svelte";
  import StaticPage from "./routes/static_page.svelte";
    import Search from "./routes/Search.svelte";

  const d = new Date();
  const n = d.getFullYear();

  $: isLoggedIn = !!$user;
  $: username = $user !== null ? $user.username : " there!";
  AOS.init();

  const appUrl = "https:////www.acidoviral.com/wp-json/";
  const usersUrl = "wp/v2/users";
  const categoryUrl = "wp/v2/categories";
  const tagsUrl = "wp/v2/tags";
  let appData;
  let userData;
  let categoryData;
  let tagsData;

  onMount(async function() {
    const appResponse = await fetch(appUrl);
    appData = await appResponse.json();
    delete appData.routes;
    appInfo.set(appData);

    //users
    const userResponse = await fetch(appUrl + usersUrl);
    userData = await userResponse.json();
    console.log(userData);
    userList.set(userData);

    //categories
    const categoryResponse = await fetch(appUrl + categoryUrl);
    categoryData = await categoryResponse.json();
    console.log(categoryData);
    categoryList.set(categoryData);

    //tags
    const tagsResponse = await fetch(appUrl + tagsUrl);
    tagsData = await tagsResponse.json();
    tagsList.set(tagsData);
  });
</script>

{#if userData && tagsData && categoryData}
  <main data-animsition-in-class="fade-in" data-animsition-out-class="fade-out">
    <Header />

    <Router>
      <Route path="/preface" component={Public} />
      <Route path="/page/:id" component={Page} />
      <Route path="/blog" component={Blog} />
      <Route path="/blog/:page" component={Blog} />

      <Route path="/category/:category" let:params>
        <Category category={params.category} page={0} />
      </Route>
      <Route path="/category/:category/:page" component={Category} />

      <Route path="/tags/:tag" component={Tag} />
      <Route path="/tags/:tag/:page" component={Tag} />

      <Route path="/post/:id" component={Post} />
      <Route path="/search/:id" component={Search} />
      <Route path="/search/:id/:page" component={Search} />

      <Route path="/portfolio" component={Portfolio} />
      <Route path="/portfolio/:id" component={Portfolio} />
      <Route path="/letter" component={Protected} />
      <Route path="/" component={Home} />
      <Route path="/:id" component={StaticPage} />
    </Router>

  </main>
  <div class="clearfix" />

  <footer class="templateux-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 text-md-left text-center">
          <p>Acido Viral</p>
        </div>
        <div class="col-md-6 text-md-right text-center footer-social">
          <a href="#" class="p-3">
            <span class="icon-facebook2" />
          </a>
          <a href="#" class="p-3">
            <span class="icon-twitter2" />
          </a>
          <a href="#" class="p-3">
            <span class="icon-dribbble2" />
          </a>
          <a href="#" class="p-3">
            <span class="icon-instagram" />
          </a>
        </div>
      </div>
    </div>
  </footer>
{:else}
  <AppLoader position="full" />
{/if}

<style>
  :global(:root) {
    --bs-link-color: inherit;
  }
  :global(a) {
    text-decoration: none;
  }
  :global(.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6) {
    font-weight: 700;
  }
  :global(.hidden) {
    display: none !important;
  }
</style>

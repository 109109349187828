<script>
  import { navigate, Link, link } from "svelte-routing";
  import { onMount } from "svelte";
  import moment from "moment";
  import { categoryList, tagsList } from "../store.js";
  import PostItem from "../common/postItem.svelte";

  import PostImage from "../common/PostImage.svelte";
  export let location;
  $: location = location;
  export let page;
  let posts = [];
  let y;
  let x;
  let pagination = page || 1;
  const postApiUrl = `https://www.acidoviral.com/wp-json/wp/v2/posts?per_page=10&page=${pagination}`;
  let not_found_items = false;

  let categories;

  onMount(async function() {
    let postresponse = await fetch(postApiUrl);
    let postsall = await postresponse.json();
    if (!postsall.data) {
      posts = postsall;
    } else {
      not_found_items = true;
      console.log(not_found_items);
      posts = [];
    }

    const unsubscribe = categoryList.subscribe(value => {
      categories = value;
    });
  });
</script>

<svelte:window bind:innerHeight={y} bind:scrollY={x} />
<svelte:head>
  <title>Lo último</title>
</svelte:head>

<section class="my-5" id="next">
  <div class="container">
    <div class="row">

      <div class="col-12">
        <h1>Últimos</h1>
        <hr />
      </div>

      {#if posts && posts.length > 0}
        {#each posts.slice(0, 20) as post}
          <PostItem {post} {categories} />
        {/each}
      {:else if not_found_items}
        <section
          class="py-5 text-center d-flex flex-row justify-content-center
          align-items-center"
        >
          <b>No se econtraron elementos</b>
        </section>
      {:else}
        <section
          class="py-5 text-center d-flex flex-row justify-content-center
          align-items-center"
        >
          <div class="dot-bricks" />
        </section>
      {/if}

      <div
        class="row pt-5 aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div class="col-md-12 d-flex flex-row justify-content-center">
          <!-- <a href="#" class="button button--red mb-5">Load More Posts...</a> -->
          <ul class="pagination">
            <li class="page-item mx-1">
              {#if parseInt(pagination) > 1}
                <a
                  use:link
                  href={`/blog/${Number(pagination) === 2 ? '' : Number(pagination) - 1}`}
                  class="btn btn-primary d-flex align-items-center"
                >
                  <span class="material-symbols-outlined">navigate_before</span>
                  Anterior
                </a>
              {:else}
                <a class="btn btn-primary" href="#" disabled>Anterior</a>
              {/if}

            </li>
            <li class="page-item mx-1">

              {#if parseInt(pagination) > 0}
                <a
                  use:link
                  href={`/blog/${Number(pagination) + 1}`}
                  class="btn btn-primary d-flex align-items-center"
                >
                  Siguiente
                  <span class="material-symbols-outlined">navigate_next</span>
                </a>
              {:else}
                <a class="btn btn-primary" href="#" disabled>Siguiente</a>
              {/if}

            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

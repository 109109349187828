<script>
  import { navigate, Link, link } from "svelte-routing";
  import { onMount } from "svelte";
  import moment from "moment";
  import { redirectURL } from "../store.js";
  import Carousel from "svelte-carousel";
  import { categoryList, tagsList } from "../store.js";

  import PostImage from "../common/PostImage.svelte";
  import PostCarouselItem from "../common/PostCarouselItem.svelte";
  import PostItem from "../common/postItem.svelte";

  let posts = [];
  let portfolio = [];
  let y;
  let x;
  let ix;

  const postApiUrl =
    "https://www.acidoviral.com/wp-json/wp/v2/posts?order=desc&per_page=12";

  const postApiUrlRelevant =
    "https://www.acidoviral.com/wp-json/wp/v2/posts?order=desc&orderby=relevance&per_page=6&search=a";

  const portApiUrl = "https://www.acidoviral.com/wp-json/wp/v2/portfolio";
  let categories;

  let most_relevant_posts;

  onMount(async function() {
    const unsubscribe = categoryList.subscribe(value => {
      categories = value;
    });

    const postresponse = await fetch(postApiUrl);
    const postsall = await postresponse.json();
    posts = postsall; /*.slice(0, 6);*/

    const post_relevant = await fetch(postApiUrlRelevant);

    most_relevant_posts = await post_relevant.json();

    let portResponse = "";
    try {
      portResponse = await fetch(portApiUrl);
    } catch {}
    let portsall = [];
    try {
      portsall = await portResponse.json();
      portfolio = portsall.slice(0, 6);
    } catch {}
  });
</script>

<svelte:window bind:innerHeight={y} bind:scrollY={x} bind:innerWidth={ix} />
<svelte:head>
  <title>Ácido Viral</title>
</svelte:head>
<section class="">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-12 aos-init aos-animate" data-aos="fade-up">
        <h1 class="mt-5">Destacados</h1>
        <hr />
        {#if most_relevant_posts && most_relevant_posts.length > 0}
          <Carousel
            particlesToShow={ix < 1025 ? 1 : 3}
            particlesToScroll={1}
            arrows={false}
            autoplay
            autoplayDuration={10000}
            autoplayProgressVisible
            infinite={false}
          >
            {#each most_relevant_posts as post}
              <PostCarouselItem {post} {categories} />
            {/each}
          </Carousel>
        {:else}
          <section
            class="py-5 text-center d-flex flex-row justify-content-center
            align-items-center"
          >
            <div class="dot-bricks" />
          </section>
        {/if}
      </div>
    </div>
  </div>
</section>
<section class="">
  <div class="container" />
</section>

<section class="my-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <h1>Últimos</h1>
        <hr />
      </div>
      <div class="col-md-12">

        <div class="row">
          {#if posts.slice(0, 16) && posts.length > 0}
            {#each posts.slice(0, 16) as post}
              <PostItem {post} {categories} />
            {/each}
          {/if}

        </div>
      </div>
    </div>
    <div class="row aos-init" data-aos="fade-up" data-aos-delay="400">
      <div class="col-md-8 ml-auto">
        <a use:link href="/blog" class="animsition-link">Ver todos</a>
      </div>
    </div>
  </div>
</section>

<style>
  :global(.sc-carousel__pages-window) {
    padding: 0;
  }
</style>

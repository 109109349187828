<script>
    import { onMount } from "svelte";
    import { navigate, link } from "svelte-routing";

    export let image;
    export let size;
    export let alt;
    export let post = {};
    export let categories;

    let postcategory;
    const apiUrl =
        "https:////www.acidoviral.com/wp-json/wp/v2/media/" +
        post.featured_media;
    let src = null;
    let images = [];

    onMount(async function() {
        if (post) {
            for (let a = 0; a < categories.length; a++) {
                if (categories[a].id == post.categories.slice(-1).pop()) {
                    postcategory = categories[a];
                }
            }
        }

        const response = await fetch(apiUrl);
        images = await response.json();

        if (
            images &&
            images.media_details &&
            images.media_details.sizes &&
            images.media_details.sizes[size]
        ) {
            src =
                images.media_details.sizes[size].source_url ||
                "/images/placeholder.png";
        } else if (images && images.source_url) {
            src = images.source_url || "/images/placeholder.png";
        } else {
            src = "/images/placeholder.png";
        }

        if (categories) {
        }
    });

    let toglr = "";

    function toggleanim(show) {
        if (show) {
            toglr = "post-data-big";
        } else {
            toglr = "";
        }
    }
</script>

{#if post}
    <a use:link href="post/{post.slug}">
        {#if src}
            <div
                class="fc-carousel-item"
                style="background-image: url('{src}');"
                on:mouseenter={() => toggleanim(true)}
                on:mouseleave={() => toggleanim(false)}
            >
                <div class="post-loader" />
                <div class="post-data {toglr}">
                    {#if postcategory}
                        <span class="badge text-bg-primary">
                            {postcategory.name}
                        </span>
                    {/if}
                    <h3>
                        {@html post.title.rendered}
                    </h3>
                </div>
            </div>
        {:else}
            <div
                class="fc-carousel-item"
                style=""
                on:mouseenter={() => toggleanim(true)}
                on:mouseleave={() => toggleanim(false)}
            >
                <div class="post-loader">
                    <div class="dot-spin" />
                </div>

                <div class="post-data {toglr}">
                    {#if postcategory}
                        <span class="badge text-bg-primary">
                            {postcategory.name}
                        </span>
                    {/if}
                    <h3>
                        {@html post.title.rendered}
                    </h3>
                </div>
            </div>
        {/if}
    </a>
{/if}

<style>
    .carousel-test {
        height: 35vh;
        padding: 20px;
        font-size: 2em;
        color: white;
    }
    .fc-carousel-item {
        height: 460px;
        width: 100%;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        /*justify-content: flex-end;*/
        justify-content: space-between;
        /*border-radius: 5px;*/
    }

    a {
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .post-loader {
        height: 50%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-end;
    }

    .post-data {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.7);
        /*
        background: linear-gradient(
            0deg,
            rgba(2, 0, 36, 0.4682247899159664) 49%,
            rgba(255, 255, 255, 0) 100%
        );*/
        background: linear-gradient(
            0deg,
            rgba(2, 0, 36, 0.4682247899159664) 71%,
            rgba(255, 255, 255, 0) 100%
        );

        padding: 1em;
        /*border-radius: 5px;*/
        transition: padding 100ms ease-in-out;
    }
    .post-data > * {
        color: #fff;
    }

    :global(.post-data-big) {
        transition: padding 300ms ease-in-out;
        padding-bottom: 2em !important;
        padding-top: 3em !important;
    }

    .badge {
        text-transform: uppercase;
    }
</style>

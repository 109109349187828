<script>
  export let position;
</script>

<div class={`boxflex ${position}`}>
  <div class="loader">
    <span class="box" />
    <span class="box" />
    <span class="txt">
      <!--
      <div class="spinner-grow text-primary" role="status">
        <span class="visually-hidden">Cargando...</span>
      </div>
      <div class="spinner-grow text-primary" role="status">
        <span class="visually-hidden" />
      </div>
      <div class="spinner-grow text-primary" role="status">
        <span class="visually-hidden" />
      </div>
    -->
      <div class="dot-bricks" />
    </span>
  </div>
</div>

<style>
  .boxflex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .boxflex.full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  .loader {
    width: 130px;
    height: 170px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .loader::before,
  .loader::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 30px;
    left: 15px;
    z-index: 1;
    transform: scale(0);
    transition: all 0.2s ease;
  }
</style>

<script>
    import { navigate, Link } from "svelte-routing";
    import { onMount } from "svelte";
    import moment from "moment";
    import { categoryList, tagsList } from "../store.js";

    import Comments from "disqus-svelte";
    import AuthorName from "../common/AuthorName.svelte";
    import { fromArray } from "../common/Util.svelte";
    export let id;
    let data = [];

    let y;
    let x;
    let categories;
    let tags;
    const apiUrl = "https://www.acidoviral.com/wp-json/wp/v2/pages?slug=" + id;
    let postcategory = "";
    let featuredsrc = null;

    let sidebar_enabled;
    let comments_enabled;

    onMount(async function() {
        console.log(tags);
        const response = await fetch(apiUrl);
        const dataArr = await response.json();
        data = dataArr[0];

        const featuredApiUrl =
            "https://www.acidoviral.com/wp-json/wp/v2/media/" +
            data.featured_media;

        if (data) {
            const response = await fetch(featuredApiUrl);
            let images = await response.json();
            let size = 0;
            if (
                images &&
                images.media_details &&
                images.media_details.sizes &&
                images.media_details.sizes[size]
            ) {
                featuredsrc =
                    images.media_details.sizes[size].source_url ||
                    "/images/placeholder.png";
            } else if (images && images.source_url) {
                featuredsrc = images.source_url || "/images/placeholder.png";
            } else {
                featuredsrc = "/images/placeholder.png";
            }
        }
    });
</script>

<svelte:window bind:innerHeight={y} bind:scrollY={x} />
<svelte:head>
    <title>
        {(data.title ?? { rendered: null }).rendered ?? 'Ácido Viral'}
    </title>
</svelte:head>
{#if data.title}
    <section class="" data-scrollax-parent="true">
        <!-- <div class="cover" data-scrollax="properties: { translateY: '30%' }"><img src="images/hero_2.jpg" /></div> -->
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12 aos-init aos-animate" data-aos="fade-up">
                    <div
                        class="post-heading"
                        style="background-image: url('{featuredsrc ? featuredsrc : '/images/placeholder.png'}');"
                    >
                        <div class="post-dummy">
                            <!---->
                        </div>
                        <div class="post-data">
                            {#if postcategory}
                                <span class="badge text-bg-primary">
                                    {postcategory.name}
                                </span>
                            {/if}
                            <h1>
                                {#if data.title}
                                    {@html data.title.rendered}
                                {/if}
                            </h1>
                            <div>{moment(data.date).format('DD MMM YYYY')}</div>
                            <div>
                                Por
                                <AuthorName author={data.author} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="mb-5" id="next">
        <div id="blog" class="site-section">
            <div class="container">
                <div class="row">
                    <div class={sidebar_enabled ? 'col-md-8' : ''}>
                        <p class="mb-5">

                            <img
                                src="images/img_1.jpg"
                                alt=""
                                class="img-fluid"
                            />
                        </p>
                        {#if data.content}
                            {@html data.content.rendered}
                        {:else}
                            <section
                                class="py-5 text-center d-flex flex-row
                                justify-content-center align-items-center"
                            >
                                <div class="dot-bricks" />
                            </section>
                        {/if}

                        <div class="tag-widget post-tag-container mb-5 mt-5">
                            {#if tags && data.tags && data.tags.length > 0}
                                <div class="tagcloud">

                                    {#each data.tags as tag}
                                        {#if fromArray(tags, 'id', tag, 'name')}
                                            <Link
                                                to={`tags/${fromArray(tags, 'id', tag, 'slug')}`}
                                                css="tag-cloud-link"
                                            >
                                                {fromArray(tags, 'id', tag, 'name')}
                                            </Link>
                                        {/if}
                                    {/each}
                                </div>
                            {/if}
                        </div>
                        <div class="pt-5 mt-5">
                            {#if comments_enabled}
                                <Comments
                                    identifier={id}
                                    siteName="test-amqyjj5qqd"
                                />
                            {/if}

                        </div>
                    </div>
                    <!-- .col-md-8 -->
                    <div
                        class="{sidebar_enabled ? 'col-md-4' : 'hidden'} sidebar
                        pl-md-5 mt-5"
                    >
                        <div class="sidebar-box">
                            <form action="#" class="search-form">
                                <div class="form-group">
                                    <span class="icon fa fa-search" />
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Buscar"
                                    />
                                </div>
                            </form>
                        </div>
                        <div class="sidebar-box">
                            <div class="categories">
                                <h3>Categorías</h3>

                                {#if categories && categories.length > 0}
                                    {#each categories as category}
                                        <li>
                                            <Link
                                                to={`category/${category.slug}`}
                                            >
                                                {category.name}
                                            </Link>
                                        </li>
                                    {/each}
                                {/if}

                            </div>
                        </div>

                        {#if tags && tags.length > 0}
                            <div class="sidebar-box">
                                <h3>Tags</h3>
                                <div class="tagcloud">

                                    {#each tags as tag}
                                        <Link
                                            to={`tags/${tag.slug}`}
                                            css="tag-cloud-link"
                                        >
                                            {tag.name}
                                        </Link>
                                    {/each}
                                </div>
                            </div>
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    </section>
{:else}
    <section
        class="py-5 text-center d-flex flex-row justify-content-center
        align-items-center"
    >
        <div class="dot-bricks" />
    </section>
{/if}

<style>
    .post-heading {
        height: 380px;
        width: 100%;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        /*justify-content: flex-end;*/
        justify-content: space-between;
        border-radius: 0 0 6px 6px;
    }

    .post-data {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.7);
        background: linear-gradient(
            0deg,
            rgba(2, 0, 36, 0.4682247899159664) 71%,
            rgba(255, 255, 255, 0) 100%
        );
        padding: 5em 2em 1em 2em;
        border-radius: 0 0 6px 6px;
    }
    .post-data > * {
        color: #fff;
    }
    .badge {
        text-transform: uppercase;
    }
</style>

<script>
    import { Router, Route, Link, navigate, link } from "svelte-routing";
    import SidebarMenu from "./sidebarMenu.svelte";
    import { fly, fade } from "svelte/transition";
    let sidebar_visible = false;
    let search_bar = "";

    let mobile_search = false;
    let mobile_menu_textarea;

    let social = window.social ?? [];

    function issueSearch() {
        let search_term = encodeURIComponent(search_bar);
        search_bar = "";
        mobile_search = false;
        navigate(`/search/${search_term}`);
    }

    function showMobileMenu() {
        mobile_search = true;
        setTimeout(() => mobile_menu_textarea.focus(), 400);
    }
</script>

<header class="templateux-navbar aos-init aos-animate" data-aos="fade-down">

    <div class="container">

        <div class="row flex-row align-items-center justify-content-between">
            <!-- movile menu -->
            <div class="col-2 d-flex d-md-none align-items-center">
                <div
                    class="d-flex align-items-center ham-container btn
                    btn-primary btn-sm mx-1"
                    on:click={() => {
                        sidebar_visible = !sidebar_visible;
                    }}
                >
                    <span class="material-symbols-outlined menu-ham">menu</span>
                </div>

            </div>

            <!-- legacy menu -->
            <div class="no-row">
                <div class="site-logo d-flex align-items-center">
                    <div
                        class="d-md-flex d-none align-items-center ham-container
                        btn btn-primary btn-sm"
                        on:click={() => {
                            sidebar_visible = !sidebar_visible;
                        }}
                    >

                        <span class="material-symbols-outlined menu-ham">
                            menu
                        </span>
                    </div>
                    <Router>
                        <a use:link class="animsition-link mx-1" href="/">
                            Ácido Viral
                        </a>
                    </Router>
                </div>
            </div>
            <!-- legacy search -->
            <div class="col-6 d-none d-md-block">
                <form
                    action="#"
                    class="search-form"
                    on:submit|preventDefault={issueSearch}
                >
                    <div class="form-group">
                        <span
                            class="icon fa fa-search search-icon"
                            on:click={issueSearch}
                            style="cursor: pointer;"
                        />
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Buscar"
                            bind:value={search_bar}
                        />
                    </div>
                </form>
            </div>
            <!-- movile search -->
            {#if mobile_search}
                <div
                    class="d-fixed d-md-none search-mobile"
                    transition:fly={{ x: 100, duration: 200, delay: 100 }}
                >
                    <form
                        action="#"
                        class="search-form"
                        on:submit|preventDefault={issueSearch}
                    >
                        <div class="form-group">
                            <span
                                class="icon fa fa-times search-icon"
                                on:click={() => (mobile_search = !mobile_search)}
                                style="cursor: pointer;"
                            />
                            <input
                                bind:this={mobile_menu_textarea}
                                type="text"
                                class="form-control"
                                placeholder="Buscar"
                                bind:value={search_bar}
                            />
                        </div>
                    </form>
                </div>
            {/if}
            <div class="no-row d-flex d-md-none">
                <button
                    type="button"
                    class="btn btn-outline"
                    on:click={showMobileMenu}
                >
                    <span class="material-symbols-outlined text-white">
                        search
                    </span>
                </button>
            </div>
            <div class="no-row text-right d-none d-lg-block">
                <nav
                    class="templateux-menu js-templateux-menu"
                    role="navigation"
                >
                    <ul class="list-unstyled">
                        <div class="d-flex flex-row justify-content-center">
                            {#each social as item}
                                {#if item.url}
                                    <a
                                        href={item.url}
                                        title={item.name}
                                        target="_blank"
                                        class="social-item"
                                    >
                                        <i
                                            class="fa {item.icon}
                                            sidebar-category-icon"
                                            aria-hidden="true"
                                        />
                                    </a>
                                {/if}
                            {/each}
                        </div>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</header>

<SidebarMenu bind:visible={sidebar_visible} />

<style>
    header {
        background-color: #27449b;
        color: #fff;
        padding: 0px;
        user-select: none;
    }
    .search-icon {
        color: #27449b;
        cursor: ponter;
    }
    .templateux-navbar .site-logo {
        top: initial;
    }
    .no-row {
        width: auto;
    }
    .templateux-navbar {
        position: initial;
    }
    .container {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
    }

    .search-mobile {
        position: fixed !important;
        z-index: 9999;
    }

    :global(header a) {
        color: #fff !important;
        text-decoration: none;
    }
    .menu-ham {
        font-size: 42px;
        cursor: pointer;
    }
    .ham-container {
        margin-right: 0.5em;
    }
    .social-item {
        margin: 0 9px;
        font-size: 2em;
    }
</style>

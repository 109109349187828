<script>
    import { navigate, link } from "svelte-routing";
    import moment from "moment";
    import PostImage from "./PostImage.svelte";
    import { onMount } from "svelte";
    export let post;
    export let categories;

    let postcategory;
    onMount(async function() {
        if (post) {
            for (let a = 0; a < categories.length; a++) {
                if (categories[a].id == post.categories.slice(-1).pop()) {
                    postcategory = categories[a];
                }
            }
        }
    });
</script>

<div class="col-lg-3" data-aos="fade-up" data-aos-delay="200">
    <a
        use:link
        href={`/post/${post.slug}`}
        css="post animsition-link aos-init"
        class="d-flex flex-column"
    >
        <figure class="figure_min rounded">
            <PostImage
                size="medium_large"
                image={post.featured_media}
                alt={post.title.rendered}
            />
        </figure>
        <div class="post-hover">
            <div class="post-hover-inner">
                {#if postcategory}
                    <span class="badge text-bg-primary">
                        {postcategory.name}
                    </span>
                {/if}
                <h4>
                    {@html post.title.rendered}
                </h4>
                <span>
                    {#if post.date}{moment(post.date).format('DD/MM/YYYY')}{/if}
                </span>

            </div>
        </div>

    </a>
</div>

<style>
    .figure_min {
        max-height: 170px;
        min-height: 170px;
        overflow: hidden;
    }
</style>

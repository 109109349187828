<script>
  import { onMount } from "svelte";

  let images = [];

  export let image;
  export let size;
  export let css;

  export let alt;
  const apiUrl = "https:////www.acidoviral.com/wp-json/wp/v2/media/" + image;
  let src = "";
  onMount(async function() {
    if (image) {
      const response = await fetch(apiUrl);
      images = await response.json();

      if (
        images &&
        images.media_details &&
        images.media_details.sizes &&
        images.media_details.sizes[size]
      ) {
        src =
          images.media_details.sizes[size].source_url ||
          "/images/placeholder.png";
      } else if (images && images.source_url) {
        src = images.source_url || "/images/placeholder.png";
      } else {
        src = "/images/placeholder.png";
      }
    }
  });
</script>

{#if src}
  <img {src} {alt} class="img-fluid {css}" />
{:else}
  <img
    src={`http://placehold.jp/24/dedede/ffffff/800x500.png?text=${alt}`}
    {alt}
    class="img-fluid {css}"
  />
{/if}

<style>
  img {
    width: 100%;
    max-height: 460px;
  }
</style>

<script>
  import { navigate, Link } from "svelte-routing";
  import { onMount } from "svelte";
  import moment from "moment";
  import { tagsList } from "../store.js";
  import { fromArray } from "../common/Util.svelte";
  import PostImage from "../common/PostImage.svelte";
  import PostItem from "../common/postItem.svelte";
  export let location;
  $: location = location;
  export let page;
  export let tag;
  let posts = [];
  let y;
  let x;
  let pagination = page || 1;
  let categories;
  let pageTitle = "";
  onMount(async function() {
    const unsubscribe = tagsList.subscribe(value => {
      categories = value;
    });
    if (categories && tag) {
      const id = fromArray(categories, "slug", tag, "id");
      pageTitle = fromArray(categories, "slug", tag, "name");
      const postApiUrl = `https://www.acidoviral.com/wp-json/wp/v2/posts?tags=${id}&per_page=10&page=${pagination}`;
      const postresponse = await fetch(postApiUrl);
      const postsall = await postresponse.json();
      posts = postsall;
    }
  });
</script>

<svelte:window bind:innerHeight={y} bind:scrollY={x} />
<svelte:head>
  <title>Tag: {pageTitle}</title>
</svelte:head>

<section class="my-5" id="next">
  <div class="container">
    <h1>Tag: {pageTitle}</h1>
    <hr />
    <div class="row">
      {#if posts && posts.length > 0}
        {#each posts.slice(0, 20) as post}
          <PostItem {post} {categories} />
        {/each}
      {:else}
        <section
          class="py-5 text-center d-flex flex-row justify-content-center
          align-items-center"
        >
          <div class="dot-bricks" />
        </section>
      {/if}

      <div class="clearfix" />
      <div
        class="row pt-5 aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div class="col-md-12 text-center">
          <!-- <a href="#" class="button button--red mb-5">Load More Posts...</a> -->

          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                {#if pagination > 0}
                  <Link
                    to={`blog/${Number(pagination) === 2 ? '' : Number(pagination) - 1}`}
                    css="page-link"
                  >
                    Previous
                  </Link>
                {:else}
                  <a class="button button--red" href="#" disabled>Previous</a>
                {/if}

              </li>
              <li class="page-item">

                {#if pagination > 0}
                  <Link to={`blog/${Number(pagination) + 1}`} css="page-link">
                    Next
                  </Link>
                {:else}
                  <a class="page-link" href="#" disabled>Next</a>
                {/if}

              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</section>

<script>
  import { navigate, Link, link } from "svelte-routing";
  import { onMount } from "svelte";
  import moment from "moment";
  import { categoryList } from "../store.js";
  import { fromArray } from "../common/Util.svelte";
  import PostImage from "../common/PostImage.svelte";
  import PostItem from "../common/postItem.svelte";
  export let location;
  $: location = location;
  export let page;
  export let category;
  let posts = [];
  let y;
  let x;
  let pagination = page || 1;
  let categories;
  let pageTitle = "";
  let not_found_items = false;

  onMount(loadCategories);

  $: category, loadCategories();

  async function loadCategories() {
    const unsubscribe = categoryList.subscribe(value => {
      categories = value;
    });
    if (categories && category) {
      posts = [];
      const id = fromArray(categories, "slug", category, "id");
      pageTitle = fromArray(categories, "slug", category, "name");
      not_found_items = false;
      let postApiUrl = `https://www.acidoviral.com/wp-json/wp/v2/posts?categories=${id}&per_page=10&page=${pagination}`;

      let postresponse = await fetch(postApiUrl);
      let postsall = await postresponse.json();
      if (!postsall.data) {
        posts = postsall;
      } else {
        not_found_items = true;
        console.log(not_found_items);
        posts = [];
      }
    }
  }
</script>

<svelte:window bind:innerHeight={y} bind:scrollY={x} />
<svelte:head>
  <title>Categoría: {pageTitle}</title>
</svelte:head>

<section class="my-5" id="next">
  <div class="container">
    <h1>Categoría: {pageTitle}</h1>
    <hr />
    <div class="row">

      {#if posts && posts.length > 0}
        {#each posts.slice(0, 20) as post}
          <PostItem {post} {categories} />
        {/each}
      {:else if not_found_items}
        <section
          class="py-5 text-center d-flex flex-row justify-content-center
          align-items-center"
        >
          <b>No se econtraron elementos</b>
        </section>
      {:else}
        <section
          class="py-5 text-center d-flex flex-row justify-content-center
          align-items-center"
        >
          <div class="dot-bricks" />
        </section>
      {/if}

      <div class="clearfix" />
      <div
        class="row pt-5 aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div class="col-md-12 d-flex flex-row justify-content-center">
          {#if !not_found_items}
            <ul class="pagination">
              <li class="page-item mx-1">
                {#if parseInt(pagination) > 1}
                  <a
                    use:link
                    href={`/category/${category}/${Number(pagination) === 2 ? '' : Number(pagination) - 1}`}
                    class="btn btn-primary d-flex align-items-center"
                  >
                    <span class="material-symbols-outlined">
                      navigate_before
                    </span>
                    Anterior
                  </a>
                {:else}
                  <a class="btn btn-primary" href="#" disabled>Anterior</a>
                {/if}

              </li>
              <li class="page-item mx-1">

                {#if parseInt(pagination) > 0}
                  <a
                    use:link
                    href={`/category/${category}/${Number(pagination) + 1}`}
                    class="btn btn-primary d-flex align-items-center"
                  >
                    Siguiente
                    <span class="material-symbols-outlined">navigate_next</span>
                  </a>
                {:else}
                  <a class="btn btn-primary" href="#" disabled>Siguiente</a>
                {/if}

              </li>
            </ul>
          {/if}
        </div>
      </div>
    </div>
  </div>
</section>

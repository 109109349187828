<script>
    import { link } from "svelte-routing";
    import { onMount } from "svelte";
    import { fly, fade } from "svelte/transition";

    export let visible = false;
    import { categoryList } from "../store.js";
    let categories;
    onMount(() => {
        const unsubscribe = categoryList.subscribe(value => {
            categories = value;
        });
        console.log(categories);
    });

    let pages = [
        {
            slug: "contacto",
            title: "Contacto",
            icon: "alternate_email"
        },
        {
            slug: "terminos-y-condiciones",
            title: "Términos y condiciones",
            icon: "edit_document"
        }
    ];

    let social = window.social ?? [];
</script>

{#if visible}
    <div
        class="overlay"
        transition:fade={{ duration: 300 }}
        on:click={() => (visible = false)}
    />
    <div
        class="menu-sidebar"
        transition:fly={{ x: -100, duration: 200, delay: 100 }}
    >
        <header
            class="d-flex flex-row align-items-center justify-content-between
            px-4"
        >

            <a href="#" class="animsition-link">Inicio</a>
            <span
                class="d-flex flex-row align-items-center btn btn-primary btn-sm"
                on:click={() => (visible = false)}
            >
                <span class="material-symbols-outlined sidebar-menu-close">
                    close
                </span>
            </span>
        </header>
        <div class="container-fluid my-3">
            <div class="row">
                <!-- Categories -->
                <div class="col-12">
                    <div class="category-list">
                        <h5 class="my-3">Categorías</h5>
                        {#each categories as category}
                            <a
                                use:link
                                href="/category/{category.slug}"
                                on:click={() => (visible = false)}
                            >
                                <span
                                    class="material-symbols-outlined
                                    sidebar-category-icon"
                                >
                                    {(category.icon ?? '').length > 0 ? category.icon : 'help'}
                                </span>
                                <span>{category.name}</span>
                            </a>
                        {/each}
                    </div>
                </div>
                <!-- Categories -->
                <!-- Pages -->
                <div class="col-12">
                    <div class="category-list">
                        <h5 class="my-3">Páginas</h5>
                        {#each pages as page}
                            <a
                                use:link
                                href="/{page.slug}"
                                on:click={() => (visible = false)}
                            >
                                <span
                                    class="material-symbols-outlined
                                    sidebar-category-icon"
                                >
                                    {(page.icon ?? '').length > 0 ? page.icon : 'help'}
                                </span>
                                <span>{page.title}</span>
                            </a>
                        {/each}
                    </div>
                </div>
                <!-- Pages -->
                <!-- social -->
                <div class="col-12">
                    <h5 class="my-3 px-3">Redes</h5>
                    <div class="d-flex flex-row justify-content-center">
                        {#each social as item}
                            {#if item.url}
                                <a
                                    href={item.url}
                                    title={item.name}
                                    target="_blank"
                                >
                                    <i
                                        class="fa {item.icon}
                                        sidebar-category-icon"
                                        aria-hidden="true"
                                    />
                                </a>
                            {/if}
                        {/each}
                    </div>
                </div>
            </div>
        </div>
    </div>
{/if}

<style>
    .menu-sidebar {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 22vw;
        z-index: 9999;
        background: #fff;
        overflow-y: auto;
    }

    .overlay {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 999;
    }

    header {
        background-color: #27449b;
        color: #fff;
        padding: 0px;
        user-select: none;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    header a {
        font-size: 30px;
    }

    .sidebar-menu-close {
        cursor: pointer;
    }
    .container-fluid {
        padding: 0 1em;
    }

    .category-list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-top: 1em;
        margin-left: 1em;
    }
    .category-list a {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        margin-bottom: 1em;
        color: #484c68;
    }
    .sidebar-category-icon {
        font-size: 45px;
        margin-right: 1em;
    }

    @media (max-width: 1024px) {
        .menu-sidebar {
            width: 100%;
        }
    }
</style>

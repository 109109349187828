
export const twitter = function (node, params) {
    let html = node.innerHTML;
    if (html.includes('platform.twitter.com/widgets.js')) {
        loadTwitterWidget();
    }
    return {
        update(params) {
            if (html.includes('platform.twitter.com/widgets.js')) {
                loadTwitterWidget();
            }
        },
        destroy() {
            removeTwitterWidget();
        }
    }
}

const loadTwitterWidget = function () {
    var id = 'twitter-wjs'

    // if script was already set, don't load it again.
    if (document.getElementById(id)) return

    var s = document.createElement('script')
    s.id = id
    s.type = 'text/javascript'
    s.async = true
    s.src = '//platform.twitter.com/widgets.js'
    document.getElementsByTagName('head')[0].appendChild(s)
}

const removeTwitterWidget = function () {
    var id = 'twitter-wjs';
    if (!document.getElementById(id)) return;
    document.getElementById(id).remove();
}